import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import "./About.css";
import logo from "../../../../Assets/About/Vector (36).svg";
import ellipse from "../../../../Assets/About/products.png";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import CFO from "../../../../Assets/About/Founder & CFO.jpg";
import COO from "../../../../Assets/About/COO.jpg";
import Typography from "@mui/material/Typography";
import quatation from "../../../../Assets/About/quatation.svg";
import Cooking from "../../../../Assets/About/cooking-img.jpg";
import Wheat1 from "../../../../Assets/About/Wheat-PNG-Clipart 3.png";
import Wheat2 from "../../../../Assets/Category/wheat.png";
import uline from "../../../../Assets/About/Line2.svg";
import stick from "../../../../Assets/About/column-line.svg";
import ContainerFour from "./containerFour";
import ContainerFive from "./containerFive";


function About() {
  return (
    <section id="about">
      <div className="aboutcontainerone">
        <div className="svg-backgroundone">
          <Container className="containerone">
            <Row>
              <Col lg={4} className="colone">
                <Row className="logoimg">
                  <img src={logo} className="logoimg" alt="logo" />
                </Row>
                <Row className="tagline">We Make Super Foods Super Tasty!</Row>
                <Row className="ellipsegroup">
                  <img src={ellipse} className="ellipseimg" alt="ellipse"/>
                </Row>
              </Col>
              <Col lg={1} className="stick-col">
                <img src={stick} alt="stick" />
              </Col>
              <Col lg={7} className="coltwo">
                <div className="coltwo-firstrow">
                  <div className="vision-head">Vision to Fulfill</div>
                  <p>
                    Fira Vision is to fulfill the diverse & distinctive culinary
                    desires of the world with qualitative and wholesome
                    innovations.
                  </p>
                </div>

                <div className="mission-head"> Mission to Evolve</div>

                <p>
               
                  Fira Mission is to evolve as an iconic brand by drawing on the
                  experience and insights of a team of professionals in the food
                  industry and by bringing to market a wide assortment of
                  differently delicious products through exploration, innovation
                  and expansion on a global scale.
                </p>
              </Col>
            </Row>
            <div className="wheat1 d-none d-md-inline d-lg-inline">
              <img src={Wheat1} alt="wheat"/>
            </div>
            <div className="wheat2 d-none d-md-inline d-lg-inline">
              <img src={Wheat2} alt="wheat"/>
            </div>
          </Container>
        </div>
      </div>
      <div className="aboutcontainertwo">
        <Container className="containertwo">
          <Row className="founderrow">
            <div className="founder"> About the Leadership</div>
            <Col
              sm
              xs={12}
              lg={{ span: 4, offset: 2 }}
              className="foundercolone"
            >
              <Card className="cardfounder">
                <CardMedia />
                <CardContent>
                  <Avatar
                    alt="Remy Sharp"
                    sx={{ width: 100, height: 100 }}
                    src={CFO}
                    className="avatar"
                  />

                  <Typography component="div" className="foundername">
                    SHIJAN ABOOBACKER
                  </Typography>
                  <Typography
                    gutterBottom
                    component="div"
                    className="foundersunhead"
                  >
                    Co-Founder & CFO
                  </Typography>
                  <Typography variant="body2" className="founderwords">
                    Cofounded FIRA Foods. Experienced in extensive business
                    avenues. Strong background in finance. Brings a wealth of
                    youthful dynamism and energy to the company. Makes sure the
                    company’s fundamentals remain solid. Instils positivity into
                    the team.
                  </Typography>
                  <img src={quatation} alt="quatation"></img>
                </CardContent>
                <CardActions></CardActions>
              </Card>
            </Col>
            <Col
              sm
              lg={{ span: 4, offset: 0 }}
              xs={12}
              className="foundercoltwo"
            >
              
              <Card className="cardfounder">
                <CardMedia />
                <CardContent>
                  <Avatar
                    alt="Remy Sharp"
                    src={COO}
                    className="avatar"
                    sx={{ width: 100, height: 100 }}
                  />

                  <Typography component="div" className="foundername">
                    SHINE SIVAPRASAD
                  </Typography>
                  <Typography
                    gutterBottom
                    component="div"
                    className="foundersunhead"
                  >
                    CEO (Global Operations)
                  </Typography>
                  <Typography variant="body2" className="founderwords">
                    Loaded with 30 years experience in retail, trading sourcing
                    and branding in markets across the Middle East, China and
                    India. Keen observer and avid learner. Open to new ideas and
                    adaptive to disruptions. Gets things done. Principled and
                    honest.
                  </Typography>
                  <img src={quatation} alt="quatation"></img>
                </CardContent>
                <CardActions></CardActions>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="aboutcontainerthree">
        <Row className="section-three">
          <Col lg={4} className="three-image">
            <img src={Cooking} alt="cooking"/>
          </Col>
          <Col lg={8} className="three-content">
            <Container>
              <div className="heading-line ms-3">
                <div className="content-heading ">
                  <span className="sp1 ">What’s Cooking</span>
                  <span className="sp2 ">&nbsp;Is who we are</span>
                </div>
              </div>
              <div className="three-contents">
                <p>
                  Fira Foods is founded and run by a team of professionals who
                  have decades of varied, worldwide experience in the food
                  industry. We source, process, manufacture, supply and market a
                  wide variety of food products. We serve up an eclectic range
                  comprising both traditional and contemporary spins. Marketed
                  under the brand name Fira, our present basket contains grains,
                  flours, sauces, soups, marinade paste, instant meals, seasoned
                  nuts, chips and nibbles.
                </p>
                <p className="content-bottom pt-5">
                  While being committed to quality, we tend to be creative with
                  some of our offerings. Our innovations such as the flour
                  blends and reinventions like the traditional Indian soups have
                  received rave reviews. With the extremely sensitive and
                  health-conscious consumer in mind, we’ve also joined the ranks
                  of vegan and gluten-free food producers. Fira is marketed
                  across the Middle East and India.
                </p>
                <div className="undeline">
                  <img src={uline} alt="underline" />
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>

      <ContainerFour />

      <ContainerFive />
    </section>
  );
}

export default About;
